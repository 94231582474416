<template>
  <content-with-sidebar class="blog-wrapper">

    <!-- blogs -->
    <b-row class="blog-list-wrapper">
      <b-col cols="12">
        <!-- pagination -->
        <div class="my-2">
          <b-pagination
              v-model="filters.page"
              :per-page="filters.perPage"
              align="center"
              :total-rows="totalMovies"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-col>

      <b-col
          v-for="movie in moviesList"
          :key="movie.id"
          md="2"
      >
        <movie-card :movie="movie"/>
      </b-col>
      <b-col cols="12">
        <!-- pagination -->
        <div class="my-2">
          <b-pagination
              v-model="filters.page"
              :per-page="filters.perPage"
              align="center"
              :total-rows="totalMovies"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>
        </div>
        <b-form-input v-model="filters.page" />
      </b-col>
    </b-row>

    <!--/ blogs -->

  </content-with-sidebar>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
} from 'bootstrap-vue'
import {avatarText, kFormatter} from '@core/utils/filter'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import store from "@/store";
import {onUnmounted, ref, watch} from "@vue/composition-api";
import useMoviesList from "./useMoviesList";
import movieStoreModule from "@/views/database/movieStoreModule";
import MovieCard from "@/views/database/MovieCard";

export default {
  components: {
    MovieCard,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    ContentWithSidebar,
  },
  data() {
    return {
      search_query: '',
      //moviesList: [],
      blogSidebar: {},
      currentPage: 1,
      perPage: 1,
      rows: 140,
    }
  },
  created() {
    //this.$http.get('/movie').then(res => { this.moviesList = res.data.data })
    //this.refetchData((response) => this.moviesList = response)
  },
  methods: {
    kFormatter,
  },
  setup() {
    const STORE_MODULE_NAME = 'movies'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, movieStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const filters = ref({
      q: '',
      categories: [],
      ratings: null,
      page: 1,
      perPage: 18,
    })
    const sortBy = ref({ text: 'Rating', value: 'rating' })

    const moviesList = ref([])
    const fetchMovies = (...args) => store.dispatch('movies/fetchMovies', ...args)

    const fetchMoviesList = () => {
      fetchMovies({
        q: filters.value.q,
        sortBy: sortBy.value.value,
        page: filters.value.page,
        per_page: filters.value.perPage,
      })
          .then(response => {
            moviesList.value = response.data.data
            totalMovies.value = response.data.meta.total
          })
    }

    fetchMoviesList()

    watch([filters, sortBy], () => {
      fetchMoviesList()
    }, {
      deep: true,
    })

    const {
      //fetchMovies,
      tableColumns,
      perPage,
      currentPage,
      totalMovies,
      dataMeta,
      perPageOptions,
      searchQuery,
      //sortBy,
      isSortDirDesc,
      refMovieListTable,
      refetchData,
    } = useMoviesList()

    return {
      fetchMovies,
      tableColumns,
      perPage,
      currentPage,
      totalMovies,
      dataMeta,
      perPageOptions,
      searchQuery,
      filters,
      sortBy,
      isSortDirDesc,
      refMovieListTable,
      refetchData,
      moviesList,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
