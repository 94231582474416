<template>
  <b-card
      tag="article"
      no-body
      class="movie-item"
      :class="itemTypeClass(movie.type)"
  >
    <b-link :to="{ name: 'movie-view', params: { id: movie.id } }">
      <b-img
          :src="movie.poster_preview"
          class="card-img-top movie-poster"
      />
    </b-link>
    <b-card-body>
      <b-card-title>
        <b-link
            :to="{ name: 'movie-view', params: { id: movie.id } }"
            class="blog-title-truncate text-body-heading"
        >
          {{ movie.title_ru }}
        </b-link>
      </b-card-title>
      <b-card-text>

      </b-card-text>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BLink,
  BImg,
  BCard,
  BCardText,
  BCardBody,
  BCardTitle,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BLink,
    BImg,
  },
  directives: {
    Ripple,
  },
  props: {
    movie: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {

    return {

    }
  },
  methods: {
    itemTypeClass(type) {
      if (type === 'FILM') return 'item-film'
      if (type === 'TV_SERIES') return 'item-series'
      if (type === 'MINI_SERIES') return 'item-series'
      if (type === 'TV_SHOW') return 'item-tv'
      return ''
    },
  }
}
</script>

<style scoped>
.item-film {
  border-style: solid;
  border-color: #1e232f;
  border-width: 2px;
}
.item-series {
  border-style: solid;
  border-color: #00b9a5;
  border-width: 2px;
}
.item-tv {
  border-style: solid;
  border-color: #7500c5;
  border-width: 2px;
}
.movie-item .movie-poster {
  max-height: 260px;
}
</style>